import React from "react";
import styles from "../home.module.scss";
// import snow from "../../../img/img5.jpg";
import { Container, Grid } from "@material-ui/core";
// import sking from "../../../img/OFFER-img-1.jpg";
// import boarding from "../../../img/img4.jpg";
// import penguins from "../../../img/penguins.jpg";
// import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ZoomedCard from "../../General/zoomedCard";
// import { pl } from "date-fns/locale";
// import useLocale from "../../../lang/useLocale";

export default function Offers(props) {
  const lang = useSelector((state) => state.lang);
  // const { getLocale } = useLocale();

  // console.log(props.data);
  return (
    <Container className={styles.offers} maxWidth="lg">
      <h6>{props.data.title}</h6>
      <h2>{props.data.subtitle}</h2>
      <Grid
        container
        spacing={1}
        className={
          (`${styles.offersWrap}`, `${lang === "ar" && `${styles.reverse}`}`)
        }
      >
        {props.data.plugin?.map((plug) => {
          return (
            // <Grid key={plug.id} item xs={12} sm={6} md={3}>
            //   <Link className={styles.linkStyle} to={"/" + lang + plug.url}>
            //     <div className={`${styles.slide}`}>
            //       <div>
            //         <div className={styles.cardImgWrap}>
            //           <img src={plug.image} alt="snow" className={styles.sking} />
            //           <div className={styles.cardWrapper}>
            //             <h4 className={styles.title}>{plug.title}</h4>
            //             <p className={styles.excerpt}>{plug.description}</p>
            //           </div>
            //         </div>
            //         <div>
            //           {/* {getLocale("learnmore")} */}
            //           {/* <Link className={styles.more} to='#'>LEARN MORE</Link> */}
            //         </div>
            //       </div>
            //     </div>
            //   </Link>
            // </Grid>
            <ZoomedCard
              key={plug.id}
              href={plug.url}
              imageUrl={plug.image}
              title={plug.title}
              description={plug.description}
            />
          );
        })}

        {/* <Grid item xs={12} sm={6} md={3}>
          <div className={`${styles.slide}`}>
            <div>
              <div className={styles.cardImgWrap}>
                <img src={sking} alt="sking" className={styles.sking} />
                <div className={styles.cardWrapper}>
                  <h4 className={styles.title}>{getLocale("SKIING")}</h4>
                  <p className={styles.excerpt}>{getLocale("SKIINGexert")}</p>
                </div>
              </div>
              <div>
                <Link className={styles.book} to={"/" + lang + "/skischool"}>
                  {getLocale("learnmore")}
                </Link> */}
        {/* <Link className={styles.more} to='/'>LEARN MORE</Link> */}
        {/* </div>
            </div>
          </div>
        </Grid> */}
        {/* <Grid item xs={12} sm={6} md={3}>
          <div className={`${styles.slide}`}>
            <div>
              <div className={styles.cardImgWrap}>
                <img src={boarding} alt="boarding" className={styles.sking} />
                <div className={styles.cardWrapper}>
                  <h4 className={styles.title}>{getLocale("SNOWBOARDING")}</h4>
                  <p className={styles.excerpt}>
                    {getLocale("SNOWBOARDINGexert")}
                  </p>
                </div>
              </div>
              <div>
                <Link className={styles.book} to={"/" + lang + "/skischool"}>
                  {getLocale("learnmore")}
                </Link> */}
        {/* <Link className={styles.more} to='/'>LEARN MORE</Link> */}
        {/* </div>
            </div>
          </div>
        </Grid> */}
        {/* <Grid item xs={12} sm={6} md={3}>
          <div className={`${styles.slide}`}>
            <div>
              <div className={styles.cardImgWrap}>
                <img src={penguins} alt="penguins" />
                <div className={styles.cardWrapper}>
                  <h4 className={styles.title}>{getLocale("PENGUINS")}</h4>
                  <p className={styles.excerpt}>{getLocale("PENGUINSexert")}</p>
                </div>
              </div>
              <div>
                <Link className={styles.book} to={"/" + lang + "/penguins"}>
                  {getLocale("learnmore")}
                </Link>
              </div>
            </div>
          </div>
        </Grid> */}
      </Grid>
    </Container>
  );
}
