import React, { useState, useRef, useEffect } from "react";
import { Container, Grid } from "@material-ui/core";
import styles from "./skiClubProgram.module.scss";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import exclamationCircle from "../../../../img/exclamation-circle.png";
import exclamationCircleSmall from "../../../../img/exclamation-circle-small.png";
import axios from "axios";
import useLocale from "../../../../lang/useLocale";
// import Popup from "../../../General/popup/popup";
// import Rules from "./rules/rules";
import loaderImg from "../../../../img/loader.gif";

const SkiClubProgram = ({ data, link, enURL, arURL, background, animation, beforePrice, price }) => {
  const { getLocale } = useLocale();
  const [loader] = useState(false);
  const animationElementRef = useRef();
  const lang = useSelector((state) => state.lang);
  const [openRegisterForm, setOpenRegisterForm] = useState(false);
  const { register, handleSubmit, errors, reset } = useForm(); // initialize the hook
  const [ageError, setAgeError] = useState(false);
  const [ageErrorMessage, setAgeErrorMessage] = useState(
    getLocale("Please Enter Your Age")
  );
  // let desc = data.description?.split('\n');
  // if (desc) { desc.shift() }
  const [submittingForm, setSubmittingForm] = useState();
  const [moveElement, setMoveElement] = useState(!animation);
  // const [forward, setForward] = useState(false);
  const [disabled, setDisabled] = useState(false);
  // const [openPopUp, setOpenPopup] = useState();

  useEffect(() => {
    if (window.location.href.includes("#register")) {
      setOpenRegisterForm(true);
    }
    if (animationElementRef.current) {
      window.onscroll = () => {
        let top = animationElementRef.current?.getBoundingClientRect().top;
        if (top <= 1000) {
          setMoveElement(true);
        } else {
          setMoveElement(false);
        }
      };
    }
  }, [animationElementRef]);

  // const onSubmit = (data) => {
  //   setDisabled(true);
  //   if (data.type === "Skiing") {
  //     if (data.age < 3) {
  //       setAgeError(true);
  //       setAgeErrorMessage(getLocale("Minimum age for Skiing is 3 years"));
  //       setDisabled(false);
  //       return;
  //     } else {
  //       setAgeError(false);
  //       setAgeErrorMessage(getLocale("Please Enter Your Age"));
  //     }
  //   } else {
  //     if (data.age < 8) {
  //       setAgeError(true);
  //       setAgeErrorMessage(
  //         getLocale("Minimum age for Snowboarding is 8 years")
  //       );
  //       setDisabled(false);
  //       return;
  //     } else {
  //       setAgeError(false);
  //       setAgeErrorMessage(getLocale("Please Enter Your Age"));
  //     }
  //   }
  //   axios
  //     .post("https://ski-back.tacverse.com/api/club", { ...data, ...{ discovery: 1 } })
  //     .then((response) => {
  //       setSubmittingForm(getLocale("Form Submitted Successfully"));
  //       reset({});
  //     })
  //     .catch((error) => {
  //       setSubmittingForm(getLocale("Error"));
  //     });
  // };
  const onSubmit = (data) => {
    setDisabled(true);
    // if (data.type === "Skiing") {
    if (data.age < 3) {
      setAgeError(true);
      setAgeErrorMessage(getLocale("Minimum age for Skiing is 3 years"));
      setDisabled(false);
      return;
    } else {
      setAgeError(false);
      setAgeErrorMessage(getLocale("Please Enter Your Age"));
    }
    // } else {
    if (data.age < 8) {
      setAgeError(true);
      setAgeErrorMessage(
        getLocale("Minimum age for Snowboarding is 8 years")
      );
      setDisabled(false);
      return;
    } else {
      setAgeError(false);
      setAgeErrorMessage(getLocale("Please Enter Your Age"));
    }
    // }
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/snow_world`, data)
      .then((response) => {
        setSubmittingForm(getLocale("Form Submitted Successfully"));
        reset({});
      })
      .catch((error) => {
        setDisabled(false);
        setSubmittingForm(getLocale("Error"));
      });
  };
  const registerForm = () => {
    if (link) {
      lang === "en" ? window.location.href = enURL : window.location.href = arURL;
    } else {
      // setForward(true);
      setOpenRegisterForm(true);
    }
  };

  // const openPopup = () => {
  //    setOpenPopup(true);
  //    setOpenPopup(undefined);
  // };

  return (
    <div
      role="img"
      aria-label="ski egypt"
      id="register"
      style={{ backgroundImage: `url(${background})` }}
      className={styles.skiClubProgramContainer}
    >
      <div dir={getLocale('dir')} style={{ transform: getLocale('dir') === "rtl" ? "scaleX(-1)" : "scaleX(1)" }}>
        <Container maxWidth="lg">
          {openRegisterForm ? (
            <Grid container>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className={`${lang === "en" ? "LEFT" : "RTL"}`}
              >
                <Grid item md={12} xs={12}>
                  <h2 className={styles.title}>
                    {getLocale("WORLD SNOW DAY IN SKI EGYPT")}
                  </h2>
                </Grid>
                <Grid item md={9} xs={12}>
                  <Grid container spacing={1}>
                    <Grid item md={6} xs={12}>
                      <div className={styles.inputContainer}>
                        <input
                          className={
                            errors.name &&
                            errors.name.type === "required" &&
                            `${styles.errorMessage}`
                          }
                          ref={register({ required: true })}
                          name="name"
                          type="name"
                          placeholder={`${lang === "en" ? "Name" : "الاسم"}`}
                        />
                        {errors.name && errors.name.type === "required" && (
                          <div className={styles.warningIcon}>
                            <img
                              src={exclamationCircle}
                              alt="error"
                              className={styles.imgError}
                            />
                            <div className={styles.errorText}>
                              <p>{getLocale("Please Enter Your Name")}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <div className={styles.inputContainer}>
                        <input
                          className={
                            errors.email &&
                            errors.email.type === "required" &&
                            `${styles.errorMessage}`
                          }
                          ref={register({ required: true })}
                          name="email"
                          type="email"
                          placeholder={`${lang === "en" ? "Email Address" : "البريد الألكتروني"
                            }`}
                        />
                        {errors.email && errors.email.type === "required" && (
                          <div className={styles.warningIcon}>
                            <img
                              src={exclamationCircle}
                              alt="error"
                              className={styles.imgError}
                            />
                            <div className={styles.errorText}>
                              <p>{getLocale("Please Enter Your Email")}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <div className={styles.inputContainer}>
                        <input
                          className={
                            errors.age &&
                            errors.age.type === "required" &&
                            `${styles.errorMessage}`
                          }
                          ref={register({ required: true })}
                          name="age"
                          type="age"
                          placeholder={`${lang === "en" ? "Age" : "السن"}`}
                        />
                        {(errors.age && errors.age.type === "required") ||
                          ageError ? (
                          <div className={styles.warningIcon}>
                            <img
                              src={exclamationCircle}
                              alt="error"
                              className={styles.imgError}
                            />
                            <div className={styles.errorText}>
                              <p>{ageErrorMessage}</p>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <div className={styles.inputContainer}>
                        <input
                          className={
                            errors.phone &&
                            errors.phone.type === "required" &&
                            `${styles.errorMessage}`
                          }
                          ref={register({ required: true })}
                          name="phone"
                          type="text"
                          placeholder={`${lang === "en" ? "Phone Number" : "رقم الهاتف"
                            }`}
                        />
                        {errors.phone && errors.phone.type === "required" && (
                          <div className={styles.warningIcon}>
                            <img
                              src={exclamationCircle}
                              alt="error"
                              className={styles.imgError}
                            />
                            <div className={styles.errorText}>
                              <p>{getLocale("Please Enter Your Phone")}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <div className={styles.inputContainer}>
                        <input
                          className={
                            errors.height &&
                            errors.height.type === "required" &&
                            `${styles.errorMessage}`
                          }
                          ref={register({ required: true })}
                          name="height"
                          type="text"
                          placeholder={`${lang === "en" ? "Height" : "طول القامة"
                            }`}
                        />
                        {errors.height && errors.height.type === "required" && (
                          <div className={styles.warningIcon}>
                            <img
                              src={exclamationCircle}
                              alt="error"
                              className={styles.imgError}
                            />
                            <div className={styles.errorText}>
                              <p>{getLocale("Please Enter Your height")}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </Grid>
                    {/* <Grid item md={12} xs={12}>
                      <div className={styles.inputContainer}>
                        <select
                          ref={register({ required: true })}
                          className={
                            errors.choose &&
                            errors.choose.type === "required" &&
                            `${styles.errorMessage}`
                          }
                          defaultValue=""
                          name="type"
                        >
                          <option value="" disabled>
                            {getLocale("Skiing/Snowboarding")}
                          </option>
                          <option value="Skiing">{getLocale("Skiing")}</option>
                          <option value="Snowboarding">
                            {getLocale("Snowboarding")}
                          </option>
                        </select>
                        {errors.type && errors.type.type === "required" && (
                          <div className={styles.warningIcon}>
                            <img
                              src={exclamationCircle}
                              alt="error"
                              className={styles.imgError}
                            />
                            <div className={styles.errorText}>
                              <p>
                                {getLocale("Please select skiing/snowboarding")}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </Grid> */}
                  </Grid>
                </Grid>
                <Grid item md={9} xs={12}>
                  <Grid container alignItems="center">
                    <Grid item md={12} xs={12}>
                      <div className={styles.inputContainer}>
                        <div
                          className={styles.note}
                          style={{ paddingBottom: "15px" }}
                        >
                          {/*  <input
                            ref={register({ required: true })}
                            name="discovery"
                            type="checkbox"
                            value="1"
                            id="checkbox"
                          />
                          <label for={"checkbox"} className={styles.labelCheckbox}>
                            {getLocale("I have completed the Discovery Lesson.")}
                          </label>
                           <Popup
                            popupTitle=""
                            popupTriggerTitle={`${lang === "en"
                              ? "Terms & Conditions"
                              : "الشروط والأحكام"
                              }`}
                            popupTriggerClass="rules"
                            margin="8px"
                          >
                            <div id="rulesPopup">
                              <Grid container item xs={12}>
                                <Grid item className="containerHeader">
                                  <p
                                    style={{
                                      display: "inline",
                                      color: "#fff",
                                    }}
                                  >{`${lang === "en"
                                    ? "Terms & Conditions"
                                    : "الشروط والأحكام"
                                    }`}</p>
                                </Grid>
                                <Grid item className="containerData">
                                  <ul className="containerUL">
                                    <Rules lang={lang} />
                                  </ul>
                                </Grid>
                              </Grid>
                            </div>
                          </Popup> */}
                        </div>

                        {errors.terms && errors.terms.type === "required" && (
                          <div
                            style={{ display: "inline-block" }}
                            className={styles.warningIcon}
                          >
                            <img
                              className={styles.smallWarning}
                              src={exclamationCircleSmall}
                              alt="small warning"
                            />
                            <div className={styles.errorText}>
                              <p>{getLocale("This Field Is Required")}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <button
                        disabled={disabled}
                        type="SUBMIT"
                        className={styles.join}
                        style={{
                          backgroundColor: disabled && "#b5b5b5",
                          color: disabled && "#3a3a3a",
                        }}
                      >
                        {data?.button ? data.button :
                          getLocale("Register Now")}
                      </button>
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Grid item md={9} xs={12}>
                <ol className={styles.listWarningMessage}>
                  <li>{getLocale("All fields are required.")}</li>
                  <li>
                    {getLocale(
                      "Minimum age for Skiing lessons is 3 years and 8 years for Snowboarding lessons"
                    )}{" "}
                  </li>
                  <li>
                    {getLocale(
                      "You have to complete the Discovery Lesson first. Find your lesson"
                    )}
                    <p
                      className={styles.linkToChooseYourLesson}
                      onClick={props.click}
                    >
                      {" "}
                      {getLocale("here!")}
                    </p>
                  </li>
                </ol>
              </Grid> */}
                {loader ? (
                  <div
                    style={{
                      height: "100vh",
                      top: "0",
                      backgroundColor: "transparent",
                      left: "0",
                    }}
                    className="loader"
                  >
                    <img src={loaderImg} alt="loader" />
                  </div>
                ) : null}
                <Grid item md={9} xs={12}>
                  <p
                    style={{
                      color: submittingForm === "Error" ? "red" : "#4BB543",
                    }}
                  >
                    {submittingForm}
                  </p>
                </Grid>
              </form>
            </Grid>
          ) : (
            <Grid
              container
              ref={animationElementRef}
              className={`${styles.skiClupProgramContainerForAnimation} ${moveElement ? styles.appear : null
                }`}
            >
              <Grid item md={12} xs={12}>
                <Grid item md={7} xs={12}>
                  <p className={styles.intro}>{data.subtitle}</p>
                  <h2 className={styles.title}>
                    {data.title}
                  </h2>
                  {data.registration &&
                    <p style={{ padding: 0 }}>
                      {data.registration}
                    </p>
                  }
                  {/* <p> */}

                  {/* {data.description?.split('\n')[0]} */}
                  {/* {data.description} */}
                  <div dangerouslySetInnerHTML={{ __html: data.description }} />
                  {/* </p> */}
                </Grid>
              </Grid>
              <Grid item md={7} xs={12}>
                <Grid style={{ marginTop: 15 }} container alignItems="center">
                  <Grid item md={5} xs={12} className={styles.btnContainer}>
                    <button className={styles.join} onClick={registerForm}>
                      {data?.button ? data.button :
                        getLocale("BOOK NOW")}
                    </button>
                  </Grid>
                  {/* <Grid item md={7} xs={12} className={styles.priceContainer}>
                    <p className={styles.price}>
                      <span className={styles.beforePrice}>{beforePrice}</span>
                      <br />
                      {price}
                      <span>/{getLocale("Per Person")}</span>
                    </p>
                  </Grid> */}
                  <Grid item md={12} xs={12}>
                    {/* <p className={styles.note} style={{ marginTop: "20px" }}>
                      {data?.note ? data.note :
                        getLocale(
                          "*Limited Slots Available - pre-registration is required*"
                        )}
                    </p> */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Container>
      </div >
    </div >
  );
};
export { SkiClubProgram };
