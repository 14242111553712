import React, { useState } from "react";
import styles from "./santa.module.scss";
import newHome from "../../../../../src/img/santasection.png";
import { useLayoutEffect } from "react";
import useLocale from "../../../../lang/useLocale";

function Santa(packages) {
  console.log(packages.packages.plugin)
  const { getLocale } = useLocale();

  const [isMobile, setIsMobile] = useState(false);
  useLayoutEffect(() => {
    if (window.innerWidth <= 1078) {
      setIsMobile(true);
    }
  }, []);
  return (
    <div className={styles.santaContainer} dir={getLocale("dir")}>
      {isMobile && (
        <div className={styles.santaSectionText}>
          <h1>{getLocale("Meet & Greet Santa")}</h1>
          <p>
            {getLocale(
              "Unwrap the magic of Christmas this holiday season with our new exclusive Christmas packages!"
            )}
          </p>
        </div>
      )}
      <div className={styles.santaSection} dir={getLocale("dir")}>
        <div className={styles.santaSectionImage}>
          <img src={newHome} alt="santa" />
        </div>
        <div className={styles.santaSectionContent}>
          {!isMobile && (
            <div className={styles.santaSectionText}>
              <h1>{getLocale("Meet & Greet Santa")}</h1>
              <p>
                {getLocale(
                  "Unwrap the magic of Christmas this holiday season with our new exclusive Christmas packages!"
                )}
              </p>
            </div>
          )}
          <div className={styles.santaSectionPackages}>
            {packages.packages.plugin && packages.packages.plugin.map((pkg, index) => (
              <div key={index} className={styles.santaSectionPackagesOffers}>
                <h4>{getLocale(pkg.name)}</h4>
                <h3>
                  {pkg.price} {getLocale("LE")}
                </h3>
                <a
                  href={`${pkg.book}`}
                >
                  {getLocale("BOOK NOW")}
                </a>
              </div>
            ))}
            {/* <div className={styles.santaSectionPackagesOffers}>
              <h4>{getLocale("Snow Classic")}</h4>
              <h3>650 {getLocale("LE")}</h3>
              <a
                href={`https://booking.skiegy.com/${lang}/christmas/booking/snow-classic-christmas`}
              >
                {getLocale("BOOK NOW")}
              </a>
            </div>
            <div className={styles.santaSectionPackagesOffers}>
              <h4>{getLocale("Christmas Pass")}</h4>
              <h3>900 {getLocale("LE")}</h3>
              <a
                href={`https://booking.skiegy.com/${lang}/ski-school/booking/christmas-pass`}
              >
                {getLocale("BOOK NOW")}
              </a>
            </div>
            <div className={styles.santaSectionPackagesOffers}>
              <div className={styles.bestValue}>
                <p>{getLocale("Best Value")}</p>
              </div>
              <div
                style={{ border: "none", padding: "0 20px 20px 20px" }}
                className={styles.santaSectionPackagesOffers}>
                <h4>{getLocale("Christmas Snow Premium")}</h4>
                <h3>1700 {getLocale("LE")}</h3>
                <a
                  href={`https://booking.skiegy.com/${lang}/ski-school/booking/vip-snow-with-santa`}
                >
                  {getLocale("BOOK NOW")}
                </a>
              </div>
            </div>
            {/* <div className={styles.santaSectionPackagesOffers}>
              <h4>{getLocale("Christmas Pass Group Package (4+1)")}</h4>
              <h3>1700 {getLocale("LE")}</h3>
              <a
                href={`https://booking.skiegy.com/${lang}/ski-school/booking/vip-snow-with-santa`}
              >
                {getLocale("BOOK NOW")}
              </a>
            </div> */}
            {/* <div className={styles.santaSectionPackagesOffers}>
              <div className={styles.bestValue}>
                <p>{getLocale("Best Value")}</p>
              </div>
              <div
                style={{ border: "none", padding: "0 20px 20px 20px" }}
                className={styles.santaSectionPackagesOffers}
              >
                <h4>{getLocale("Christmas Snow Premium Group Package (4+1)")}</h4>
                <h3>6800 {getLocale("LE")}</h3>
                <a
                  href={`https://booking.skiegy.com/${lang}/ski-school/booking/christmas-pass-family`}
                >
                  {getLocale("BOOK NOW")}
                </a>
              </div>
            </div> */}
          </div>
          <div className={styles.compare}>
            <a href={`/${getLocale("dir") === "ltr" ? "en" : "ar"}/prices`}>
              {getLocale("Compare Christmas Packages")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Santa;
