import React, { useState, useEffect, useCallback } from "react";
import { Grid, Select, MenuItem } from "@material-ui/core";
import { Link, withRouter, useHistory, useLocation } from "react-router-dom";
import styles from "./header.module.scss";
import { useDispatch, useSelector } from "react-redux";
// import logo from "../../img/logo.svg";
import logo from "../../img/logo.png";
import useLocale from "../../lang/useLocale";
import TopBar from "./topbar";
import burgerDark from "../../img/burgerDark.svg";
import close from "../../img/close.svg";
// import phoneWhite from "../../img/phoneWhite.svg";
// import searchIcon from '../../img/searchWhite.svg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faYoutube
} from "@fortawesome/free-brands-svg-icons";
import { handleLang } from "../../redux/actions/lang";
import { handleSize } from "../../redux/actions/size";
import tiktok from "../../img/tik-tok-white.svg";
import _ from "lodash";
import closeIcon from "../../img/CloseIcon.svg";

function Header(props) {
  const browserLang = useSelector(state => state.lang);
  const dispatch = useDispatch();
  const { getLocale } = useLocale();
  const [toggleMenu, setToggleMenu] = useState(false);
  // const [closeCookie, setCloseCookie] = useState((getCookie("enable_cookie") === "1") ? false : true);
  const [closeCookie, setCloseCookie] = useState(false);

  function openMenu() {
    setToggleMenu(!toggleMenu);
  }
  //Determine Size of Screen Hook
  // eslint-disable-next-line
  const size = useWindowSize();
  // const lang = useSelector((state) => state.lang);

  function useWindowSize() {
    function getSize() {
      return window.innerWidth;
    }
    useEffect(() => {
      window.addEventListener(
        "resize",
        _.debounce(() => {
          dispatch(handleSize(getSize()));
        }, 1000)
      );
      return () => window.removeEventListener("resize", null);
    }, []);
  }
  const sizeRedux = useSelector(state => state.size);
  // const [, setLang] = useState("en");
  // const [lang, setLang] = useState("en");
  const [time, setTime] = useState("");
  // Function to make a dynamic clock
  const startTime = useCallback(() => {
    let now = new Date();
    let hours = now.getHours();
    let minutes = now.getMinutes();
    minutes = checkTime(minutes);
    setTime(hours + ":" + minutes);
  }, []);
  function checkTime(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }
  useEffect(() => {
    setInterval(() => {
      startTime();
    }, 1000);
    return () =>
      clearInterval(() => {
        startTime();
      });
  }, [startTime]);
  // Function to alternate degree depending on time
  const [degree, setDegree] = useState(-4);
  useEffect(() => {
    let now = new Date();
    let hours = now.getHours();
    // console.log(hours)
    if (hours === 0) {
      setDegree(-3);
    } else if (hours > 0 && !(hours > 6)) {
      setDegree(-2);
    } else if (hours >= 6 && !(hours > 12)) {
      setDegree(-4);
    } else if (hours >= 12 && !(hours > 18)) {
      setDegree(-2);
    } else if (hours >= 18 && !(hours > 23)) {
      setDegree(-3);
    } else if (hours >= 23) {
      setDegree(-4);
    }
  }, []);
  let history = useHistory();
  let location = useLocation();
  function changeLang(e) {
    // setLang(e.target.value);
    dispatch(handleLang(e.target.value));
    document.cookie = `lang=${e.target.value}; expires=Fri, 31 Dec 9999 23:59:59 GMT; SameSite=None; Secure`;
    // let url = '/' + lang + props.location.pathname.substring(3);
    let url = "/" + e.target.value + location.pathname.substring(3);
    history.push(url);
  }

  function acceptCookie() {
    document.cookie = `enable_cookie=1; expires=Fri, 31 Dec 9999 23:59:59 GMT; SameSite=None; Secure`;
    setCloseCookie(false);
  }

  // function getCookie(cName) {
  //   const name = cName + "=";
  //   const cDecoded = decodeURIComponent(document.cookie); //to be careful
  //   const cArr = cDecoded.split('; ');
  //   let res;
  //   cArr.forEach(val => {
  //     if (val.indexOf(name) === 0) res = val.substring(name.length);
  //   })
  //   return res;
  // }

  // eslint-disable-next-line
  // const [lang, setLang] = useState("en");
  if (sizeRedux > 1024) {
    return (
      <header className={styles.siteHeader}>
        {closeCookie ? (
          <div className={styles.cookieBannerContainer} dir={getLocale("dir")}>
            <div className={styles.content}>
              <p>
                {getLocale(
                  "This website uses cookies to improve your experience and to personalize our content to you."
                )}{" "}
                <br />{" "}
                {getLocale(
                  "By continuing to use this website you consent to the use of cookies. For more information, refer to our"
                )}{" "}
                <a
                  style={{
                    fontWeight: "800",
                    textDecoration: "underline",
                    color: "white",
                  }}
                  tabindex="-1"
                  href={`https://privacy-center-dev.majidalfuttaim.com/client/2d5739a0-dda3-4b4a-a445-c058ae6dd4eb?lang=${browserLang}&country=egy#/privacy-policy`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy.
                </a>{" "}
              </p>
            </div>
            <div
              className={styles.close}
              dir={getLocale("dir")}
              onClick={() => acceptCookie()}
            >
              <div>
                <img src={closeIcon} alt="close" />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div
          className={`${styles.headerWrapper} ${props.location.pathname === "/" ||
            props.location.pathname.includes("about")
            ? null
            : "whiteHeader"
            }`}
        >
          <TopBar size={sizeRedux} degree={degree} time={time} />
          <Grid
            container
            justify="center"
            alignItems="center"
            className={styles.header}
            direction={browserLang === "ar" ? "row-reverse" : null}
          >
            <Grid item xs={2}>
              <Link to={"/" + browserLang + "/"} rel="canonical">
                <img src={logo} alt="logo" />
              </Link>
            </Grid>
            <Grid item xs={10}>
              <Grid
                container
                justify={browserLang === "ar" ? "flex-start" : "flex-end"}
                alignItems="center"
              >
                <Grid item className={styles.menu}>
                  <nav>
                    <ul>
                      <li>
                        <Link
                          to={"/" + browserLang + "/aboutus"}
                          rel="canonical"
                        >
                          {getLocale("ABOUT")}
                        </Link>
                      </li>

                      <li>
                        <Link to={"/" + browserLang + "/skischool"}>
                          {getLocale("SKI SCHOOL")}
                        </Link>
                      </li>
                      {/*<li>
                        <Link to={"/" + browserLang + "/summer-camp"}>
                          {getLocale("SUMMER CAMP")}
                        </Link>
                      </li>*/}
                      <li>
                        <Link to={"/" + browserLang + "/professionals"}>
                          {getLocale("PROFESSIONALS")}
                        </Link>
                      </li>
                      {/* <li>
                        <Link to={"/" + browserLang + "/christmaspackage"}>
                          <span style={{ "color": "#ad0a22" }}>{getLocale("CHRISTMAS PACKAGE")}</span>
                        </Link>
                      </li> */}
                      <li>
                        {/* <Link to="/packages">{getLocale("PACKAGES")}</Link> */}
                        <Link
                          to={"/" + browserLang + "/prices"}
                          rel="canonical"
                        >
                          {getLocale("PRICES")}
                        </Link>
                      </li>
                      {/* <li> */}
                      {/* <div className={styles.dropdown}>
                          <button className={styles.dropbtn}>{getLocale("GROUP PACKAGES")}</button>
                          <div className={styles.dropdownContent}>
                            <Link to="/corporateBenefits">Corporate Benefits</Link>
                            <Link to="/dayTrip">Day Trip</Link>
                            <Link to="/teamBuilding">Team Building</Link>
                            <Link to="/birthdayParties">Birthday Parties</Link>
                          </div>
                        </div> */}
                      {/* <Link to="/penguins">{getLocale("GROUP PACKAGES")}</Link> */}
                      {/* </li> */}
                      <li>
                        <Link
                          to={"/" + browserLang + "/groupPackages"}
                          rel="canonical"
                        >
                          {getLocale("GROUP PACKAGES")}
                        </Link>
                      </li>
                      <li>
                        <Link to={"/" + browserLang + "/penguins"}>
                          {getLocale("PENGUINS")}
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          to={"/" + browserLang + "/offers"}
                          rel="canonical"
                        >
                          {getLocale("OFFERS")}
                        </Link>
                      </li> */}
                    </ul>
                  </nav>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </header>
    );
  } else {
    return (
      <header className={styles.siteHeader}>
        {closeCookie ? (
          <div className={styles.cookieBannerContainer} dir={getLocale("dir")}>
            <div className={styles.content} dir={getLocale("dir")}>
              <p>
                {getLocale(
                  "This website uses cookies to improve your experience and to personalize our content to you."
                )}{" "}
                <br />{" "}
                {getLocale(
                  "By continuing to use this website you consent to the use of cookies. For more information, refer to our"
                )}{" "}
                <a
                  style={{
                    fontWeight: "800",
                    textDecoration: "underline",
                    color: "white",
                  }}
                  tabindex="-1"
                  href={`https://privacy-center-dev.majidalfuttaim.com/client/2d5739a0-dda3-4b4a-a445-c058ae6dd4eb?lang=${browserLang}&country=egy#/privacy-policy`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy.
                </a>{" "}
              </p>
            </div>
            <div
              className={styles.close}
              dir={getLocale("dir")}
              onClick={() => acceptCookie()}
            >
              <div>
                <img src={closeIcon} alt="close" />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {toggleMenu ? (
          <div className={styles.mobileMenu}>
            <div className={styles.upper}>
              <Grid container justify="space-between" alignItems="center">
                <Grid item className={styles.close}>
                  <img src={close} alt="close" onClick={openMenu} />
                </Grid>
                <Grid item className={styles.icons}>
                  {/* <span>
                    <a href="tel:16002">
                      <img src={phoneWhite} alt="phone icon" />
                    </a>
                  </span> */}
                  {/* <span>
                                        <Link to='/'>
                                            <img src={searchIcon} alt="search icon" />
                                        </Link>
                                    </span> */}
                </Grid>
              </Grid>
            </div>
            <Grid
              container
              justify="center"
              alignItems="center"
              className={styles.navWrap}
            >
              <Grid item onClick={openMenu}>
                <nav>
                  <ul>
                    <li>
                      <Link to={"/" + browserLang + "/aboutus"} rel="canonical">
                        {getLocale("ABOUT")}
                      </Link>
                    </li>
                    <li>
                      <Link to={"/" + browserLang + "/skischool"}>
                        {getLocale("SKI SCHOOL")}
                      </Link>
                    </li>
                    {/*<li>
                      <Link to={"/" + browserLang + "/summer-camp"}>
                        {getLocale("SUMMER CAMP")}
                      </Link>
                    </li>*/}
                    <li>
                      <Link to={"/" + browserLang + "/professionals"}>
                        {getLocale("PROFESSIONALS")}
                      </Link>
                    </li>
                    {/* <li>
                      <Link to={"/" + browserLang + "/christmaspackage"} >
                        <span>{getLocale("CHRISTMAS PACKAGE")}</span>
                      </Link>
                    </li> */}
                    <li>
                      {/* <Link to="/packages">{getLocale("PACKAGES")}</Link> */}
                      <Link to={"/" + browserLang + "/prices"} rel="canonical">
                        {getLocale("PRICES")}
                      </Link>
                    </li>
                    <li>
                      {/* <div className={styles.dropdown}>
                        <button className={styles.dropbtn}>{getLocale("GROUP PACKAGES")}</button>
                        <div className={styles.dropdownContent}>
                          <Link to="/corporateBenefits">Corporate Benefits</Link>
                          <Link to="/dayTrip">Day Trip</Link>
                          <Link to="/teamBuilding">Team Building</Link>
                          <Link to="/birthdayParties">Birthday Parties</Link>
                        </div>
                      </div> */}
                      <Link
                        to={"/" + browserLang + "/groupPackages"}
                        rel="canonical"
                      >
                        {getLocale("GROUP PACKAGES")}
                      </Link>
                    </li>
                    <li>
                      <Link to={"/" + browserLang + "/penguins"}>
                        {getLocale("PENGUINS")}
                      </Link>
                    </li>
                    {/* <li>
                      <Link to={"/" + browserLang + "/offers"} rel="canonical">
                        {getLocale("OFFERS")}
                      </Link>
                    </li> */}
                  </ul>
                </nav>
              </Grid>
            </Grid>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <div className={styles.lowerLeft}>
                  <a
                    href="https://www.facebook.com/skiegypt"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                  <a
                    href="https://www.instagram.com/ski.egypt/?hl=en"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCggXDtcjumJo_b_Rex5xnOg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faYoutube} />
                  </a>
                  <a
                    href="https://www.tiktok.com/@ski.egypt?is_copy_url=1&is_from_webapp=v1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      style={{ width: "20px" }}
                      src={tiktok}
                      alt="ticktok icon"
                    />
                  </a>
                </div>
              </Grid>
              <Grid item>
                <Select
                  onChange={changeLang}
                  value={browserLang}
                  className={styles.lowerRight}
                >
                  <MenuItem value="en">ENGLISH</MenuItem>
                  <MenuItem value="ar">عربي</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </div>
        ) : null}
        <div className={styles.headerWrapper}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className={styles.header}
          >
            <Grid item xs={6}>
              <Grid container alignItems="center">
                <Grid
                  item
                  xs={2}
                  style={{ cursor: "pointer" }}
                  onClick={openMenu}
                >
                  <img
                    src={burgerDark}
                    alt="burger"
                    style={{ maxWidth: "20px" }}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Link to={"/" + browserLang + "/"} rel="canonical">
                    <img
                      src={logo}
                      alt="logo"
                      className={styles.logoMobile}
                      style={{ marginLeft: "10px" }}
                    />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} className={styles.snow}>
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="row"
                className={styles.boxwrap}
              >
                <Grid item className={styles.degree}>
                  <span>{degree} &#8451;</span>
                </Grid>
                <Grid item className={styles.time}>
                  {time}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </header>
    );
  }
}

export default withRouter(Header);
